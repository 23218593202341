.tab_out_box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  overflow: hidden;
  user-select: none;
  background-color: white;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
  transition: .5s;

  .tabs_box {
    width: calc(100% - 50px);
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;

    // align-items: center;
    // padding: 0px 4px;
    // background: red;
    .tabs {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0px 10px;
      margin: 2px 0px;
      background-color: rgba(228, 228, 228, .6);
      width: auto;
      margin-right: 2px;
      min-width: 70px;

      .tabs_text {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        padding: 0 10px;
      }
    }

    .tab_custom {
      display: flex;
      align-items: center;
      height: 36px;
      padding: 0px 10px;
      margin: 2px 0px;
      width: auto;
      margin-right: 2px;
      min-width: 70px;
    }


    .tabs:hover {
      cursor: pointer;
    }

    .tab_active {
      background: #4686FC !important;
      padding: 0px 10px;
    }

    .tabs_text_active {
      color: white;
    }

    .tabs_icon_active {
      color: white;
    }

    .home_box {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 38px;
      height: 38px;
      margin: 1px 0px;
      margin-right: 2px;
      width: 40px;
    }

    .home_box:hover {
      cursor: pointer;
    }

    .home_null_box {
      display: none;
    }
  }

  .spread_icon {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 38px;
    height: 38px;
    margin: 1px 0px;
    margin-right: 2px;
    width: 40px;
    display: none;
  }

  .show_spread {
    display: block;
  }

  .square_outlined {
    display: inline-block;
    transition: .3s;
  }

  .show_right {
    transform: rotate(0);
  }

  .show_Down {
    transform: rotate(90deg);
  }

  .current_path {
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }
}