.pagebox {
    // background-image:url("../../assets/images/u0_div.png");
    // background-size: 100% 100%;
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 1100px;

    .left_box {
        background-image: url("../../assets/images/login/background.png");
        background-size: 100% 100%;
    }

    .logo_img {
        position: absolute;
        top: 30px;
        left: 30px;

        img {
            width: 200px;
            height: auto;
        }

        .logo_text {
            font-size: 20px;
            color: white;
        }
    }

    .footer {
        position: absolute;
        z-index: 10;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;

        .f_text {
            color: white;
            text-align: center;

            .content {
                font-size: 22px;
                margin-bottom: 20px;
            }

            .info {
                div {
                    font-size: 12px;
                }
            }
        }
    }
}