.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999999;
  transition: 2s;
}
.showLoading{
    // opacity: 1;
    display: flex;
}
.hiddenLoading {
    display: none;
//   opacity: 0;
}
