.error_box {
    width: 100%;
    height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;
  .error_inner_box {
      text-align: center;
  }
  .error_title {
    text-align: center;
    margin: 20px 0px;
    font-size: 30px;
    font-weight: bold;

  }
  .error_message {
    text-align: center;
    color: rgb(141, 139, 139);
  }
  .error_btn_box{
      margin-top: 20px;
  }
}
