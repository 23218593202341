.cart_box {
    background: white;
    padding: 20px;

    .title_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;
    }
}