.project_detail_box {
  background: white;
  position: relative;
  .btn_box {
    padding: 20px;
    border-bottom: 1px solid #ccc;

    .btn_right {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      &>div{
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          margin-right:10px;
      }
    }
  }
  .table_box {
    padding: 20px;
  }
  .tip{
    margin-top: 10px;
    position: absolute;
    bottom: 40px;
    left: 20px;
    display: flex;
    align-items: center;
    div{
      font-size: 17px;
      font-weight: bold;
      margin-right: 15px;
    }
  }
}
