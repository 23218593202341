.index_box {
    // min-width: 1400px;
    // overflow: hidden;
    margin: 0 !important;

    .card {
        margin-bottom: 20px;
        border: 1px solid #EDEFF2;
    }

    .pic_box {
        position: relative;

        .pic_select {
            position: absolute;
            right: 20px;
            top: 14px;
            // height: 40px;
            // background: red;
        }


    }

    .pic_title {
        font-size: 20px;
        text-align: center;
        font-weight: bold;
    }

    .table_box {
        position: relative;

        .table_select {
            position: absolute;
            right: 20px;
            top: 14px;
            display: flex;
            align-items: center;
            // height: 40px;
            // background: red;
        }
    }

    .card_box {
        display: flex;

        .ant-card-body {
            padding: 15px !important;

        }

        .item_box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin: 0 !important;
        }

        .card_item {
            width: 25%;
            min-width: 350px;

            .card_inner_box {
                height: 164px;
                width: 98%;
                background: #FFFFFF;
                box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.05);
                border-radius: 2px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-bottom: 30px;
                padding: 0px 20px;
            }

            img {
                padding-right: 10px;
                width: 70px;
            }

            .num {
                font-size: 26px;
            }

            .txt {
                font-size: 14px;
                color: rgb(141, 138, 138);
            }
        }

        .rectangle_item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 0;

        }

        .rectangle {
            border: 1px solid #ffffff;
            box-sizing: border-box;
            background: #F0F0F0;
            width: 82px;
            height: 146px;
            margin-bottom: 20px;

            .img_box {
                padding-top: 10px;
                display: flex;
                justify-content: center;

                img {
                    width: 50px;
                }
            }

            .num {
                font-size: 20px;

                text-align: center;
            }

            .txt {
                padding: 12px 0px;
                text-align: center;
                font-size: 14px;
                color: rgb(141, 138, 138);
            }
        }
    }

    .top_car_box {
        align-items: flex-start;
    }

    .pic_item {
        width: 33.33%;
    }

    .maintenance_item {
        width: 50%;
        display: inline-block;
    }

    @media (max-width:1100px) {
        .rectangle_item {
            display: flex;
            justify-content: space-around !important;
        }

        .card_item {
            width: 50% !important;
        }

        .maintenance_item {
            width: 100% !important;
        }

    }


    @media (max-width:1500px) {
        .card_box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .log_card {
            width: 100% !important;
        }

        .pic_item {
            width: 100%;
            margin-bottom: 20px;
        }

        .map_item {
            height: 400px;
        }

        .maintenance_item {
            width: 50%;
        }
    }

    @media (max-width:1300px) {
        .item_box {
            justify-content: space-around !important;
        }
    }

    @media (max-width:1050px) {
        .card {
            width: 100% !important;

        }

        .card_box {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .ant-card-body {
            padding: 30px !important;
        }

        .pic_item {
            margin-bottom: 20px;
            width: 100%;
        }

        .map_item {
            height: 400px;
        }

        .maintenance_item {
            width: 50%;
        }
    }

    .left_card {
        width: calc(100% - 33.5%);
        // max-width: 1086px;
    }



}