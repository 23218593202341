.log_box{
    background: white;
    .btn_box{
        padding: 20px;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btn_right{
            display: flex;
            align-items: center;
        }
    }
    .table_box{
        padding: 20px;
    }
}