.loginfrom_page {
  z-index: 20;
  transition: .5s;
  width: 350px;

  .loginfrom_page_title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    // color: white;
    margin-bottom: 10px;
  }

  .loginfrom_box {
    transition: .5s;
    height: 400px;
    background: #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 486px;
      height: auto;
    }

    .line {
      height: 300px;
      width: 2px;
      background: #a0a0a0;
    }

    .formbox {
      width: 486px;
      display: flex;
      justify-content: center;
      align-items: center;

      .form_titel {
        font-size: 24px;
        color: #4484e5;
        margin-bottom: 20px;
      }

      .inner_box {
        background: white;
        width: 350px;
        height: 370px;
        padding: 10px 30px;
      }
    }
  }
}


@media (min-width: 1400px) {
  // .loginfrom_page {
  //   width: 1100px !important;
  // }

  // .loginfrom_box {
  //   width: 1100px !important;
  // }
}