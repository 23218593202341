.order_box {
    background: white;

    .btn_box {
        padding: 20px;
        border-bottom: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: flex-start;

        align-items: center;
        flex-wrap: wrap;
    }

    .table_box {
        padding: 20px;
    }
}

.order_detail_box {
    background: white;

    .btn_box {
        // padding: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: flex-start;
        font-size: 20px;
        font-weight: bold;
        align-items: center;
        flex-wrap: wrap;
    }

    .table_box {
        padding: 20px;
    }
}