.price_box {
  background: white;
  .btn_box {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .table_box {
    padding: 20px;
    .inner_box{
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 0px 20px;
    }
    .text_box {
        width: 50%;
        padding: 0px 10px;
        border-right: 1px solid #ccc;
        box-sizing: border-box;
        height: 40px;
        line-height: 40px;
        border: 1px solid #ccc;
    }
    .price_box {
        width: 50%;
        padding: 0px 10px;
        border: 1px solid #ccc;
        border-left: none;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }
  }
}
