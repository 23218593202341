.size_box{
    background: white;
    .btn_box{
        padding: 20px;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
    .table_box{
        padding: 20px;
    }
}