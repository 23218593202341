.menu_box {
    height: calc(100vh - 70px);
    overflow-y: scroll;
    user-select: none;
    overflow-x: hidden;
}

.ant-menu-vertical,
.ant-menu-inline {
    border: none !important;
}

.ant-menu-title-content {
    color: white;
}