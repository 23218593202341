.detail_box{
    background: white;
    .btn_box{
        padding: 20px;
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: flex-end;
    }
    .table_box{
        padding: 20px;
    }
}