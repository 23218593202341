.search_box {
    width: 100%;

    .form_box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .search_item_box {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


@media (max-width:1000px) {
    .form_box {
        .search_item_box {
            width: 50% !important;
            // .seacrch_item {
            //     width: 200px !important;
            // }
        }
    }
}

@media (max-width:1570px) and (min-width:1000px) {
    .form_box {
        justify-content: flex-start !important;
        width: 100%;

        .search_item_box {
            width: 33.33% !important;
            // .seacrch_item {
            //     width: 200px !important;
            // }
        }

    }
}

@media (min-width:1570px) {
    .form_box {
        justify-content: flex-start !important;
    }

    .search_item_box {
        width: 25% !important;
    }
}