.steel_box {
    background: white;
    position: relative;

    .btn_box {
        padding: 20px;
        border-bottom: 1px solid #ccc;
        flex-wrap: wrap;

        .btn_box_right {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            &>div {
                margin-right: 10px;
            }

            .right_title {
                height: 32px;
                line-height: 32px;
                font-size: 14px;
            }
        }
    }

    .table_box {
        padding: 20px;
    }

    .tip {
        // margin-top: 10px;
        // position: absolute;
        // bottom: 40px;
        // left: 20px;
        padding-left: 1.25rem;
        padding-bottom: 1.25rem;
        display: flex;
        align-items: center;

        div {
            font-size: 17px;
            font-weight: bold;
            margin-right: 15px;
        }
    }
}