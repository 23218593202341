#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.admin_pages {
  // min-width: 1650px;
  min-width: 900px;

  .admin_title {
    width: 200px;
    text-align: center;
    font-size: 22px;
    // color: white;
    font-weight: bold;
    // box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, .3);
  }

  .logo {
    padding: 4px 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }

  .admin_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px !important;
    padding-right: 20px !important;
    background: white !important;
    // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3) !important;
    border-bottom: 1px solid rgb(204, 204, 204);

    .ant-dropdown-link {
      color: black;
    }
  }

  .tabs_menu {
    background: white !important;
    width: 100%;
    height: 40px;
    // box-shadow: 0px 2px 6px 0px rgba(0,0,0,.3);
    position: relative;
  }

  .content {
    // background: red;
    // box-sizing: border-box;
    width: calc(100% - 0px);
    // max-width: 1700px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // max-width: 1500px;
    height: calc(100% - 40px);
    min-width: 700px;
    overflow-y: scroll;
    padding: 20px;
    position: relative;
  }
}


// .cart_icon p:not(:last-child) {
//   border-bottom: 1px solid rgb(231, 231, 231);
//   padding-bottom: 10px;
// }