.company_box {
  background: white;
  .btn_box {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .table_box {
    padding: 20px;
  }
  tr td {
    // text-align: center;
  }
}
.ant-upload .ant-upload-select-picture-card {
  width: 110px !important;
  height: 110px !important;
}
